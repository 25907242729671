exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-app-privacy-policy-tsx": () => import("./../../../src/pages/legal/app/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-app-privacy-policy-tsx" */),
  "component---src-pages-legal-app-terms-tsx": () => import("./../../../src/pages/legal/app/terms.tsx" /* webpackChunkName: "component---src-pages-legal-app-terms-tsx" */),
  "component---src-pages-legal-index-tsx": () => import("./../../../src/pages/legal/index.tsx" /* webpackChunkName: "component---src-pages-legal-index-tsx" */),
  "component---src-pages-legal-legal-notice-tsx": () => import("./../../../src/pages/legal/legal-notice.tsx" /* webpackChunkName: "component---src-pages-legal-legal-notice-tsx" */),
  "component---src-pages-legal-privacy-policy-tsx": () => import("./../../../src/pages/legal/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-tsx" */)
}

